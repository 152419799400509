import axios from "axios";

async function fetch_data(
  rUrl,
  query = false,
  method = "GET",
  headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  }
) {
  this && this.count++;
  try {
    if (["GET", "DELETE"].includes(method) && query) {
      rUrl += `?${objIntoParams(query)}`;
    }
    const call_parameter = {
      method,
      headers,
    };
    if (query && ["POST", "PUT"].includes(method)) {
      call_parameter.data = objIntoParams(query);
    }
    if (rUrl.includes("?")) {
      rUrl += "&fullfill_ref=1";
    } else {
      rUrl += "?fullfill_ref=1";
    }
    let responcedData = await axios(rUrl, call_parameter);
    if (responcedData?.status === 200) {
      responcedData = responcedData["data"];
    }
    return responcedData;
  } catch (error) {
    console.error("FullFill: ", error);
    return {
      _fail: true,
    };
  }
}

async function updatefetch(token) {
  if (token) {
    window.fetch = new Proxy(window.fetch, {
      apply: function (target, that, args) {
        if (args[0]?.includes(window.FULLFILL_CONFIG.API_PATH)) {
          if (args[1]) {
            if (args[1].headers) {
              args[1].headers.Authorization = token;
            } else {
              args[1].headers = {
                Authorization: token,
              };
            }
          } else {
            args[1] = {
              headers: { Authorization: token },
            };
          }
        }
        return target.apply(that, args);
      },
    });
    window.XMLHttpRequest = new Proxy(window.XMLHttpRequest, {
      construct: function (target, args) {
        const xhr = new target(...args);
        xhr.onreadystatechange = function () {
          if (
            xhr._url?.includes(window.FULLFILL_CONFIG.API_PATH) &&
            xhr.readyState === 1
          ) {
            xhr.setRequestHeader("Authorization", token);
          }
        };
        return xhr;
      },
    });
  }
  return true;
}

function objIntoParams(obj, prefix) {
  let str = [];
  let p = Object.keys(obj);
  let len = p.length;
  while (len--) {
    if (obj.hasOwnProperty(p[len])) {
      let k = prefix ? `${prefix}[${p[len]}]` : p[len],
        v = obj[p[len]];
      str.push(
        v !== null && typeof v === "object"
          ? objIntoParams(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      );
    }
  }
  return str.join("&");
}

function convert_into_moneyformate(e, t) {
  e = e.toString();
  let r = e,
    o = (-1 < e.toString().indexOf(".") && (r = e.split(".")), "0");
  function a(e, t) {
    return void 0 === e ? t : e;
  }
  function i(e, t, r, i) {
    if (
      ((t = a(t, 2)), (r = a(r, ",")), (i = a(i, ".")), isNaN(e) || null === e)
    )
      return 0;
    3 == o
      ? (e = (e / 1e3).toFixed(t))
      : (0 == o && 0 == t) || (e = (0 == o ? e : e / 100).toFixed(t));
    t = e.split(".");
    return (
      t[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + r) +
      (t[1] ? i + t[1] : "")
    );
  }
  if (
    (Array.isArray(r) && null != r[1] && (o = r[1].length), void 0 !== e && e)
  ) {
    "string" == typeof e && (e = e.replace(".", ""));
    let n = "",
      r = /\{\{\s*(\w+)\s*\}\}/;
    t = t || "${{amount}}";
    try {
      switch (t.match(r)[1]) {
        case "amount":
          n = i(e, 2);
          break;
        case "amount_no_decimals":
          n = i(e, 0);
          break;
        case "amount_with_comma_separator":
          n = i(e, 2, ".", ",");
          break;
        case "amount_with_space_separator":
          n = i(e, 2, " ", ",");
          break;
        case "amount_with_period_and_space_separator":
          n = i(e, 2, " ", ".");
          break;
        case "amount_no_decimals_with_comma_separator":
          n = i(e, 0, ".", ",");
          break;
        case "amount_no_decimals_with_space_separator":
          n = i(e, 0, " ");
          break;
        case "amount_with_apostrophe_separator":
          n = i(e, 2, "'", ".");
      }
      return t.replace(r, n);
    } catch (e) {}
  }
  return e;
}

function dollarUS(currency = "USD") {
  return Intl.NumberFormat(undefined, {
    style: "currency",
    currency,
  });
}

async function postRequest(url, body) {
  return await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error");
      }
      return response.json();
    })
    .catch((error) => {
      console.error("error:", error);
    });
}

export {
  fetch_data,
  updatefetch,
  convert_into_moneyformate,
  dollarUS,
  postRequest,
};
