import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../css/display_gift.scss";
import { dollarUS } from "../helper/functions";
import placeholder from "../public/placeholder-image.jpg";

function CustomGift(props) {
  const shop_info = useSelector((state) => state.shopInfoReducer.shopInfo);
  const { gift } = props;
  const [img, set_img] = useState(placeholder);
  const [variant_title, set_variant_tile] = useState();
  const [price, set_price] = useState();

  useEffect(() => {
    try {
      const varian_id = gift.variant_id;
      if (gift?.shopify_product) {
        let is_found = gift.shopify_product.images?.find((img) =>
          img.variant_ids.includes(varian_id)
        );
        if (is_found?.src) {
          set_img(is_found.src);
        } else if (gift.shopify_product.image?.src) {
          set_img(gift.shopify_product.image.src);
        }
        let variant = gift.shopify_product.variants.find(
          (variant) => variant.id === varian_id
        );
        if (variant) {
          //   variant.price = Number(variant?.price);
          if (variant.title !== "Default Title") {
            set_variant_tile(variant.title);
          }
          if (variant.price !== 0) {
            let price = dollarUS(shop_info.currency).format(variant.price);
            set_price(<span className="original-price">{price}</span>);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <div className="w1 relative f jcb cart-item">
        <a
          href="/products/sweetener-concealer?variant=42620723691783"
          tabIndex="-1"
          className="cart-item__image relative block js-tabable-item-cart"
        >
          <div className="img fit-contain is-loaded position-center transparent">
            <img
              src={img}
              alt="concealer"
              data-fit="contain"
              className="img__el"
            />
          </div>
        </a>
        <div className="cart-item__data flex justify-between">
          <div className="cart-item__data flex justify-between">
            <div className="cart-item__data__info charcoal-black">
              {/* <span className="p2 dark-grey">sweetener</span>{" "} */}
              <h2 className="cart-item__title black h4--quantity-selector">
                {gift?.shopify_product?.title || ""}
              </h2>
              <div className="cart-item__option p2--label">
                {variant_title ? ` / ${variant_title}` : ""}
              </div>
            </div>
            <div className="cart-item__price p3 align-r h4--alt">
              <span>
                <span className="h4--alt red">Free</span>
                <br />
              </span>
            </div>
          </div>
          <div className="cart-item__qty flex-grow ">
            <div className="cart-item__qty__wrapper align-c">
              <div
                className="quantity-selector h4--quantity-selector"
                small="true"
              >
                <p className="quantity-selector__field label">{gift.qty}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomGift;
