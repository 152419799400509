import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import Homecart from "./Homecart";
import { store } from "./redux/store";
import { fetch_data, postRequest } from "./helper/functions";

config().then(is_cart).catch(console.error);

async function config() {
  let API_PATH, MODE;

  const fullfill_current_script_url = document.currentScript.src;
  const fullfill_domain_name = fullfill_current_script_url
    .replace("http://", "")
    .replace("https://", "")
    .split(/[/?#]/)[0];
  const shop_name = window?.Shopify?.shop || "";
  let Location = window.location;

  if (
    fullfill_domain_name === "localhost:3001" ||
    fullfill_domain_name === "localhost"
  ) {
    API_PATH = "http://localhost:3000/";
    MODE = "local";
  } else if (fullfill_domain_name === "dev.fullfill.co") {
    API_PATH = "http://dev.fullfill.co/";
    MODE = "dev";
  } else if (fullfill_domain_name === "www.fullfill.co") {
    API_PATH = "http://www.fullfill.co/";
    MODE = "production";
  }

  const checkout_button = [
    ".rebuy-cart__checkout-button",
    '[name="checkout"]',
    '[href="/checkout"]',
    'button[name="checkout"]',
    "button[type=submit]",
    "form[action='/checkout']",
    "form[action='/checkout'] button[type='submit']",
  ];
  const add_to_cart_button = [
    'button[name="add"]',
    'button[name="addtocart"]',
    ".product-form .quantity-submit-row .quantity-submit-row__submit button[type=submit]",
    ".header-cart__close",
    ".product-form--add-to-cart",
    ".product-form-submit-wrap",
    ".ProductForm__AddToCart",
    'input[name="add"]',
  ];
  const additional_checkout_button = [
    ".additional-checkout-buttons",
    ".dynamic-checkout__content",
  ];
  const cart_form = [
    'form[action="/cart"]:not(#cart-notification-form)',
    'form[action*="/cart"]:not(#cart-notification-form)',
    'form[action="/cart?locale=en"]:not(#cart-notification-form)',
    'form[action="/checkout"]:not(#cart-notification-form)',
  ];
  const product_from = ['form[action="/cart/add"]', 'form[id="playform"]'];
  const form_id = [
    "cart-form",
    "cart",
    "cart_form",
    "CartPageForm",
    "",
    "site-cart-form-page",
    "updateform",
    "cartform",
    "cml_checkout_form",
    "cartForm",
    "frm-cart",
  ];

  window.FULLFILL_CONFIG = {
    shop_name,
    API_PATH,
    MODE,
    checkout_button,
    add_to_cart_button,
    additional_checkout_button,
    cart_form,
    product_from,
    form_id,
    Location,
    path: Location.pathname,
  };
}

async function is_cart(shop_info) {
  const FULLFILL_CONFIG = window.FULLFILL_CONFIG;

  if (FULLFILL_CONFIG.path === "/cart") {
    let cart_form = "";
    if (
      FULLFILL_CONFIG.shop_name === "rapid-prod.myshopify.com" ||
      FULLFILL_CONFIG.shop_name === "rapid-project-dev.myshopify.com"
    ) {
      cart_form = document.querySelectorAll(".main .cart-page");
    } else {
      cart_form = document.querySelectorAll(FULLFILL_CONFIG.cart_form);
    }
    if (cart_form?.[0]) {
      load_CSS();
      cart_form = cart_form[0];
      const fullfill_div = document.createElement("div");
      fullfill_div.className = "fullfill";
      FULLFILL_CONFIG.fullfill_div = fullfill_div;
      // cart_form.appendChild(fullfill_div);
      cart_form.insertAdjacentElement("afterend", fullfill_div);
      ReactDOM.render(
        <Provider store={store}>
          <App shop={FULLFILL_CONFIG.shop_name} />
        </Provider>,
        fullfill_div
      );
    }
  } else if (
    FULLFILL_CONFIG.shop_name === "rapid-prod.myshopify.com" ||
    FULLFILL_CONFIG.shop_name === "rapid-project-dev.myshopify.com"
  ) {
    setInterval(() => {
      const fullfillDiv = document.querySelector(".fullfill");
      if (!fullfillDiv) {
        console.log("run me");
        let cart_form = "";
        if (
          FULLFILL_CONFIG.shop_name === "rapid-prod.myshopify.com" ||
          FULLFILL_CONFIG.shop_name === "rapid-project-dev.myshopify.com"
        ) {
          cart_form = document.querySelectorAll(".js-header-cart");
        } else {
          cart_form = document.querySelectorAll(FULLFILL_CONFIG.cart_form);
        }
        if (cart_form?.[0]) {
          load_CSS();
          console.log("add tag");
          cart_form = cart_form[0];
          const fullfill_div = document.createElement("div");
          fullfill_div.className = "fullfill";
          FULLFILL_CONFIG.fullfill_div = fullfill_div;
          // cart_form.appendChild(fullfill_div);
          cart_form.insertAdjacentElement("afterend", fullfill_div);
          ReactDOM.render(
            <Provider store={store}>
              <Homecart shop={FULLFILL_CONFIG.shop_name} />
            </Provider>,
            fullfill_div
          );
        }
      }
    }, 1000);
  }

  window.addEventListener("DOMContentLoaded", function () {
    setInterval(() => {
      let checkEvent = null
      const checkEvent_other = document.querySelector(
        "form[action='/checkout'] button[type='submit']"
      );
      const dev_checkEvent = document.querySelector('.cart__checkout-button')
      if (checkEvent_other != null) {
        checkEvent = checkEvent_other;
      }
      else if (dev_checkEvent != null) {
        checkEvent = dev_checkEvent;
      }
      if (checkEvent != null) {
        checkEvent.addEventListener("click", async function (event) {
          // Event handler code goes here
          event.preventDefault(); // Prevent the default form submission behavior
          event.stopImmediatePropagation();
          event.stopPropagation();
          let items = [];
          let cart_data = await fetch_data(
            "/cart.json?ref=" + new Date().getTime()
          );
          let find_gift_product = await fetch_data(
            `${window.FULLFILL_CONFIG.API_PATH}get-gift?generate_discount=true`,
            { cart_data },
            "POST"
          );
          if (find_gift_product?.data?.flowsIds.length) {
            if (find_gift_product?.data?.products?.length) {
              let filter_flow_data = find_gift_product?.data?.products.filter(
                (s) => s.flow_id
              );
              if (filter_flow_data.length) {
                let discount = find_gift_product?.data.discount;
                items =
                  filter_flow_data?.map((s) => ({
                    id: s?.variant_id,
                    qty: s?.qty,
                    properties: {
                      _ff_gift: true,
                    },
                  })) || [];
                if (items.length) {
                  let success = false;
                  if (find_gift_product.data.show_me.page_checkout == 1) {
                    success = await postRequest("/cart/add.js", {
                      items: items,
                    });
                  }
                  if (find_gift_product?.data?.flowsIds.length) {
                    await postRequest("/cart/update.js", {
                      attributes: {
                        ff_flow_ids:
                          find_gift_product?.data?.flowsIds.toString(),
                      },
                    });
                  }
                  console.log('success', success, discount)
                  if (success) {
                    if (discount) {
                      console.log("Discount Found SucscessFully ");
                      window.location.href = "/checkout?discount=" + discount;
                    } else {
                      window.location.href = "/checkout";
                    }
                  } else {
                    window.location.href = "/checkout";
                  }
                }
              }
            }
          } else {
            window.location.href = "/checkout";
          }
        });
      }
    }, 1000);
  });
}

function load_CSS() {
  var head = document.getElementsByTagName("head")[0];
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = `${window.FULLFILL_CONFIG.API_PATH}front_script/static/css/main.css`;
  link.media = "all";
  head.appendChild(link);
}
