import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../css/display_gift.scss";
import { dollarUS } from "../helper/functions";
import placeholder from "../public/placeholder-image.jpg";

function DisplayGift(props) {
  const shop_info = useSelector((state) => state.shopInfoReducer.shopInfo);
  const { gift } = props;
  const [img, set_img] = useState(placeholder);
  const [variant_title, set_variant_tile] = useState();
  const [price, set_price] = useState();

  useEffect(() => {
    try {
      const varian_id = gift.variant_id;
      if (gift?.shopify_product) {
        let is_found = gift.shopify_product.images?.find((img) =>
          img.variant_ids.includes(varian_id)
        );
        if (is_found?.src) {
          set_img(is_found.src);
        } else if (gift.shopify_product.image?.src) {
          set_img(gift.shopify_product.image.src);
        }
        let variant = gift.shopify_product.variants.find(
          (variant) => variant.id === varian_id
        );
        if (variant) {
          variant.price = Number(variant.price);
          if (variant.title !== "Default Title") {
            set_variant_tile(variant.title);
          }
          if (variant.price !== 0) {
            let price = dollarUS(shop_info.currency).format(variant.price);
            set_price(<span className="original-price">{price}</span>);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <div className="gift--body">
        <div className="image--section">
          <div className="image">
            <img src={img} alt="" height="101" loading="lazy" />
          </div>
          <div className="image--text">
            <p>
              {" "}
              {gift?.shopify_product?.title || ""}
              {variant_title ? ` / ${variant_title}` : ""}
            </p>
            <p>{price} Free</p>
          </div>
        </div>
        <div>{gift.qty}</div>
      </div>
    </>
  );
}

export default DisplayGift;
