import { useEffect, useState } from "react";
import { fetch_data, updatefetch } from "./helper/functions";
import DisplayGift from "./component/DisplayGift";
import CustomGift from "./component/CustomGift";
import { useDispatch, useSelector } from "react-redux";
import { setShopInfo } from "./redux/shopInfoSlice";

function App({ shop }) {
  const dispatch = useDispatch();
  const shop_info = useSelector((state) => state.shopInfoReducer.shopInfo);
  const [gifts, set_gifts] = useState([]);

  useEffect(() => {
    get_shop_info();
  }, []);

  useEffect(async () => {
    getGift();
  }, [shop_info]);

  async function getGift() {
    if (shop_info) {
      let cart_data = await fetch_data("/cart.json");
      let find_gift_product = await fetch_data(
        `${window.FULLFILL_CONFIG.API_PATH}get-gift`,
        { cart_data },
        "POST"
      );
      if (find_gift_product?.data?.products?.length && find_gift_product?.data?.show_me?.cart_checkout == 1) {
        set_gifts(find_gift_product.data.products);
        moveDiv(100);
      }
    }
  }
  async function get_shop_info() {
    let data = await fetch(
      `${window.FULLFILL_CONFIG.API_PATH}shopInfo?shop=${window.FULLFILL_CONFIG.shop_name}`
    );
    const token = data.headers.get("Authorization");
    await updatefetch(token);
    let storeInfo = (await data.json()).data;
    dispatch(setShopInfo(storeInfo));
  }

  useEffect(() => {
    function addToCart() {
      const myButtons = document.getElementsByClassName(
        "product-add-to-cart__btn"
      );

      // Loop through each button and attach the event listener
      for (let i = 0; i < myButtons.length; i++) {
        myButtons[i].addEventListener("click", async function (event) {
          console.log("Button clicked!");
          setTimeout(() => {
            get_shop_info();
          }, 1000);
        });
      }
    }
    addToCart();
  }, []);

  function moveDiv(time) {
    const fullfillDiv = document.querySelector(".fullfill");
    const headerCartDiv = document.querySelector(
      ".js-header-cart .header-cart .header-cart__body .header-cart__body__scroll .cart-lines"
    );
    setTimeout(() => {
      if (fullfillDiv.firstChild) {
        headerCartDiv.insertAdjacentElement("afterend", fullfillDiv);
      } else {
        console.log("no child found");
        moveDiv(1000);
      }
    }, time);
  }
  return (
    <>
      {gifts?.length ? (
        shop === "rapid-prod.myshopify.com" ||
          shop === "rapid-project-dev.myshopify.com" ? (
          <>
            <div
              class="header-cart__header align-c relative"
              style={{ border: "none" }}
            >
              <div class="cart-progress align-c realtive">
                <h1 id="cart-title" class="h4--minicart cart-progress__title">
                  <span>{gifts.length}</span> <span>gifts</span>
                </h1>
                <div data-ge-hide="" class="cart-progress__free-shipping-bar">
                  <div class="cart-progress__free-shipping-bar__progress relative">
                    <div class="cart-progress__free-shipping-bar__progress__fill"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="custom--style--gift container cart-page__title"
              style={{ width: "100%" }}
            >
              {gifts?.map((gift) => {
                return (
                  <>
                    <CustomGift gift={gift} />
                  </>
                );
              })}
            </div>
          </>
        ) : (
          <div className="gift">
            <h3>Gifts </h3>
            <div className="gift--style">
              <div className="gift--head">
                <div>Product</div>
                <div>Quantity</div>
              </div>
              {gifts?.map((gift) => {
                return (
                  <>
                    <DisplayGift gift={gift} />
                  </>
                );
              })}
            </div>
          </div>
        )
      ) : null}
    </>
  );
}

export default App;
