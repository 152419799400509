// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const shopInfoSlice = createSlice({
  name: "shopInfo",
  initialState: {
    shopInfo: undefined,
    giftsInfo: [],
  },
  reducers: {
    setShopInfo: (state, action) => {
      state.shopInfo = action.payload;
    },
    setGiftsInfo: (state, action) => {
      state.giftsInfo = action.payload;
    },
  },
});

export const { setShopInfo, setGiftsInfo } = shopInfoSlice.actions;

export default shopInfoSlice.reducer;
