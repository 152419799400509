import { useEffect, useState } from "react";
import { fetch_data, postRequest, updatefetch } from "./helper/functions";
import DisplayGift from "./component/DisplayGift";
import CustomGift from "./component/CustomGift";
import { useDispatch, useSelector } from "react-redux";
import { setShopInfo, setGiftsInfo } from "./redux/shopInfoSlice";

function App({ shop }) {
  const dispatch = useDispatch();
  const shop_info = useSelector((state) => state.shopInfoReducer.shopInfo);
  const [gifts, setGifts] = useState([]);

  useEffect(() => {
    get_shop_info();
  }, []);

  useEffect(() => {
    async function gift_data() {
      if (shop_info) {
        let cart_data = await fetch_data(
          "/cart.json?ref=" + new Date().getTime()
        );
        await postRequest("/cart/update.js", {
          attributes: { _ff_gift: null },
        });
        if (cart_data && cart_data.items.length) {
          const itemsToUpdate = cart_data?.items
            ?.filter((item) => item?.properties?._ff_gift)
            ?.map((item) => ({ id: `${item?.variant_id}`, quantity: 0 }));
          if (itemsToUpdate?.length) {
            for (const item of itemsToUpdate) {
              await postRequest("/cart/change.js", item);
            }
            window.location.reload();
          }
        }

        let find_gift_product = await fetch_data(
          `${window.FULLFILL_CONFIG.API_PATH}get-gift`,
          { cart_data },
          "POST"
        );
        if (find_gift_product?.data?.products?.length && find_gift_product?.data?.show_me?.cart_checkout == 1) {
          setGifts(find_gift_product.data.products);
        }
      }
    }
    gift_data();
  }, [shop_info]);

  async function get_shop_info() {
    let data = await fetch(
      `${window.FULLFILL_CONFIG.API_PATH}shopInfo?shop=${window.FULLFILL_CONFIG.shop_name}`
    );
    const token = data.headers.get("Authorization");
    await updatefetch(token);
    let storeInfo = (await data.json()).data;
    dispatch(setShopInfo(storeInfo));
  }

  useEffect(() => {
    // Add event listener to checkout button
    async function ev_handler(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
      let items = [];
      let cart_data = await fetch_data(
        "/cart.json?ref=" + new Date().getTime()
      );
      let find_gift_product = await fetch_data(
        `${window.FULLFILL_CONFIG.API_PATH}get-gift?generate_discount=true`,
        { cart_data },
        "POST"
      );
      if (find_gift_product?.data?.flowsIds.length) {
        if (find_gift_product?.data?.products?.length) {
          let filter_flow_data = find_gift_product?.data?.products.filter(
            (s) => s.flow_id
          );
          if (filter_flow_data.length) {
            let discount = find_gift_product?.data.discount;
            items =
              filter_flow_data?.map((s) => ({
                id: s?.variant_id,
                qty: s?.qty,
                properties: {
                  _ff_gift: true,
                },
              })) || [];
            if (items.length) {
              let success = await postRequest("/cart/add.js", { items: items });
              if (find_gift_product?.data?.flowsIds.length) {
                await postRequest("/cart/update.js", {
                  attributes: {
                    ff_flow_ids: find_gift_product?.data?.flowsIds.toString(),
                  },
                });
              }
              if (success) {
                if (discount) {
                  window.location.href = "/checkout?discount=" + discount;
                } else {
                  window.location.href = "/checkout";
                }
              }
            }
          }
        }
      } else {
        window.location.href = "/checkout";
      }
    }
    const myCheckout = document.querySelectorAll([
      "form[action='/checkout'] button[type='submit']",
      "#checkout",
    ]);
    let buttonlength = myCheckout.length;
    while (buttonlength--) {
      const result = myCheckout[buttonlength];
      result.addEventListener("click", ev_handler);
    }

    return () => {
      let buttonlength = myCheckout.length;
      while (buttonlength--) {
        const result = myCheckout[buttonlength];
        result.removeEventListener("click", ev_handler);
      }
    };
  }, [gifts]);

  return (
    <>
      {gifts?.length ? (
        shop === "rapid-prod.myshopify.com" ||
          shop === "rapid-project-dev.myshopify.com" ? (
          <div className="custom--style--gift container cart-page__title">
            <h3 className="custom--gift">Gifts ({gifts.length}) </h3>

            {gifts?.map((gift) => {
              return (
                <>
                  <CustomGift gift={gift} />
                </>
              );
            })}
          </div>
        ) : (
          <div className="gift">
            <h3>Gifts </h3>
            <div className="gift--style">
              <div className="gift--head">
                <div>Product</div>
                <div>Quantity</div>
              </div>
              {gifts?.map((gift) => {
                return (
                  <>
                    <DisplayGift gift={gift} />
                  </>
                );
              })}
            </div>
          </div>
        )
      ) : null}
    </>
  );
}

export default App;
